import { work_orderService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, work_orders: [], work_order: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        work_orderService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        work_orderService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/work_orders');
                    dispatch('alert/success', "Work Order Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/work_orders');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        work_orderService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/work_orders');
                    dispatch('alert/success', "Work Order Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/work_orders');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        work_orderService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      work_orderService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/work_orders');
                  dispatch('alert/success', "Work Order Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/work_orders');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_work_order_by_date({ dispatch, commit }, data) {
        commit('request');

        work_orderService.get_work_order_by_date(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_work_order_by_account({ dispatch, commit }, data) {
        commit('request');

        work_orderService.get_work_order_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_maintenance_history_by_vehicle({ dispatch, commit }, data) {
        commit('request');

        work_orderService.get_maintenance_history_by_vehicle(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  work_orders: state => {
    return state.work_orders
  },
  work_order: state => {
    return state.work_order
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.work_order = {};
        state.work_orders = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.work_order = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.work_orders = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const work_orders = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
