import { vehicle_routeService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, vehicle_routes: [], vehicle_route: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        vehicle_routeService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        vehicle_routeService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/vehicle_routes');
                    dispatch('alert/success', "Vehicle Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/vehicle_routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        vehicle_routeService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/vehicle_routes');
                    dispatch('alert/success', "Vehicle Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/vehicle_routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        vehicle_routeService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      vehicle_routeService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/vehicle_routes');
                  dispatch('alert/success', "Vehicle Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/vehicle_routes');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_by_account({ dispatch, commit }, data) {
        commit('request');

        vehicle_routeService.get_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  vehicle_routes: state => {
    return state.vehicle_routes
  },
  vehicle_route: state => {
    return state.vehicle_route
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.vehicle_route = {};
        state.vehicle_routes = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.vehicle_route = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.vehicle_routes = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const vehicle_routes = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
