import { routeService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, routes: [], route: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        routeService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        routeService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/routes');
                    dispatch('alert/success', "Route Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        routeService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/routes');
                    dispatch('alert/success', "Route Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        routeService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      routeService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/routes');
                  dispatch('alert/success', "Route Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/routes');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_route_by_account({ dispatch, commit }, data) {
        commit('request');

        routeService.get_route_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_route_by_vehicle({ dispatch, commit }, data) {
        commit('request');

        routeService.get_route_by_vehicle(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit_route_stops({ dispatch, commit }, data) {
        commit('request');

        routeService.edit_route_stops(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/routes');
                    dispatch('alert/success', "Route Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit_route_vehicles({ dispatch, commit }, data) {
        commit('request');

        routeService.edit_route_vehicles(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/routes');
                    dispatch('alert/success', "Route Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit_route_points({ dispatch, commit }, data) {
        commit('request');

        routeService.edit_route_points(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/routes');
                    dispatch('alert/success', "Route Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/routes');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  routes: state => {
    return state.routes
  },
  route: state => {
    return state.route
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.route = {};
        state.routes = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.route = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.routes = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const routes = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
