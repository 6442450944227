import { road_warrantService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, road_warrants: [], road_warrant: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        road_warrantService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        road_warrantService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/road_warrants');
                    dispatch('alert/success', "Road Warrant Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/road_warrants');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        road_warrantService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/road_warrants');
                    dispatch('alert/success', "Road Warrant Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/road_warrants');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        road_warrantService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      road_warrantService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/road_warrants');
                  dispatch('alert/success', "Road Warrant Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/road_warrants');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_road_warrant_by_vehicle({ dispatch, commit }, data) {
        commit('request');

        road_warrantService.get_road_warrant_by_vehicle(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_road_warrant_by_account({ dispatch, commit }, data) {
        commit('request');

        road_warrantService.get_road_warrant_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  road_warrants: state => {
    return state.road_warrants
  },
  road_warrant: state => {
    return state.road_warrant
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.road_warrant = {};
        state.road_warrants = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.road_warrant = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.road_warrants = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const road_warrants = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
