import { user_capabilityService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, user_capability: []};

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        user_capabilityService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        user_capabilityService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/users');
                    dispatch('alert/success', "Data Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/users');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  user_capability: state => {
    return state.user_capability
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.user_capability = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.user_capability = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const user_capabilities = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
