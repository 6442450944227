import { vehicle_commandService } from '../_services';
const state = { onRequest: false, vehicle_commands: [] };

const actions = {
    get_vehicle_command({ dispatch, commit }, data) {
        commit('request');

        vehicle_commandService.get_vehicle_command(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    send_vehicle_command({ dispatch, commit }, data) {
        commit('request');

        vehicle_commandService.send_vehicle_command(data)
            .then(
                () => {
                    commit('success');
                    dispatch('alert/success', "Command Sent Successfully", { root: true });
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    reset_vehicle_command({ commit }) {
        commit('clear');
    }
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  vehicle_commands: state => {
    return state.vehicle_commands
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.vehicle_commands = data;
    },
    error(state) {
      state.onRequest = false;
    },
    clear(state) {
      state.vehicle_commands = [];
    }
};

export const vehicle_commands = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
