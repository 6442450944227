import { userService } from '../_services';
import { router } from '../_helpers';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { user, onRequest: false, users: [], u: {} }
    : { user: null, onRequest: false, users: [], u: {} };

const actions = {
    login({ dispatch, commit }, { email, password }) {
        commit('request');

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
        router.push('/login');
    },
    edit_profile({ dispatch, commit }, { user }) {
        commit('request');

        userService.update(user)
            .then(
                user => {
                    commit('updateSuccess', user);
                    dispatch('alert/success', "Profile Updated Successfully", { root: true });
                },
                error => {
                    commit('updateError');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    change_password({ dispatch, commit }, { password, repassword }) {
        commit('request');

        userService.change_password(password, repassword)
            .then(
                () => {
                    commit('updatePasswordSuccess');
                    dispatch('alert/success', "Password Updated Successfully", { root: true });
                },
                error => {
                    commit('updateError');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get({ dispatch, commit }, id) {
        commit('request');

        userService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        userService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/users');
                    dispatch('alert/success', "User Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/users');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        userService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/users');
                    dispatch('alert/success', "User Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/users');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    reset_password({ dispatch, commit }, data) {
        commit('request');

        userService.reset_password(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/users');
                    dispatch('alert/success', "User Password Reset Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/users');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        userService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      userService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/users');
                  dispatch('alert/success', "User Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/users');
                  dispatch('alert/error', error, { root: true });
              }
          );
    }
};
const getters = {
  user: state => {
    return state.user
  },
  u: state => {
    return state.u
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
    },
    loginSuccess(state, user) {
        state.onRequest = false;
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.onRequest = false;
        state.user = null;
    },
    updateSuccess(state, user) {
        state.onRequest = false;
        state.user = user;
    },
    updatePasswordSuccess(state) {
        state.onRequest = false;
    },
    updateError(state) {
        state.onRequest = false;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    success(state){
      state.onRequest = false;
    },
    success_datas(state, data){
      state.onRequest = false;
      state.users = data;
    },
    success_data(state, data){
      state.onRequest = false;
      state.u = data;
    },
    error(state) {
      state.onRequest = false;
    },
};

export const users = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
