import { report_generateService } from '../_services';

const state = { onRequest: false, report_generate: [] };

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        report_generateService.get_data(data)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  report_generate: state => {
    return state.report_generate
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.report_generate = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.report_generate = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const report_generates = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
